<template>
    <div class="openapiview">
      <progress v-if="!currentSpec" />
      <div v-if="currentSpec">
        <div class="header">
          <h1>{{ currentSpec.title }}</h1>
          <div class="response-buttons">
            <button class="approve" @click="setStatus('RELEVANT')">
              Approve Selected
            </button>
            <button class="reject" @click="setStatus('NOT_RELEVANT')">
              Reject All
            </button>
          </div>
        </div>
        <div class="spec-details">
          <div>{{ currentSpec.spec.info.description }}</div>
          <a :href="currentSpec.url">{{ currentSpec.url }}</a>
        </div>
        <div class="endpoints">
          <label
            v-for="endpoint in currentSpec.endpoints"
            :key="endpoint.id"
            class="endpoint"
          >
            <input type="checkbox" v-model="endpoint.checked" id="endpoint.id">
            <div :for="endpoint.id">
              <h3>{{ endpoint.method.toUpperCase()}}: {{endpoint.url}}</h3>
              <div>{{ endpoint.description}}</div>
            </div>
          </label>
        </div>
      </div>
    </div>
  </template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      currentSpec: null,
      currentIndex: 0,
    };
  },
  mounted() {
    this.next();
  },
  methods: {
    async next() {
      const { data } = await axios.get('http://localhost:1111/openapi/next');
      this.currentSpec = data;
      console.log('Loaded');
    },
    async setStatus(status) {
      const endpoints = this.currentSpec.endpoints.map((ep) => ({
        id: ep.id,
        status: ep.checked ? 'RELEVANT' : 'NOT_RELEVANT',
      }));
      console.log('Endpoints', endpoints);
      // Intentionally don't wait to make it faster
      axios.put(`http://localhost:1111/openapi/spec/${this.currentSpec.id}/status`, {
        status,
        endpoints,
      });
      this.next();
    },
  },
};
</script>

<style scoped>
.openapiview {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.spec-details {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.response-buttons {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  gap: 10px;
}
button {
  color: white;
  border-style: none;
  font-size: inherit;
  font-style: inherit;
  height: 40px;
  padding: 10px;
  border-radius: 5px;
}
button.approve {
  background-color: green;
}
button.reject {
  background-color: red;
}

.endpoint {
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px #eee;
  padding-top: 10px;
  padding-bottom: 10px;
  gap: 10px;
}
.endpoint input {
  width: 30px;
  height: 30px;
}
h3 {
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
