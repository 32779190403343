import { render, staticRenderFns } from "./OpenapiView.vue?vue&type=template&id=33fd7954&scoped=true"
import script from "./OpenapiView.vue?vue&type=script&lang=js"
export * from "./OpenapiView.vue?vue&type=script&lang=js"
import style0 from "./OpenapiView.vue?vue&type=style&index=0&id=33fd7954&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33fd7954",
  null
  
)

export default component.exports