<template>
  <div class="editor-view">
    <div v-if="manifestLoading">
      <progress />
    </div>
    <div v-else-if="manifestFailed" class="manifest-failed">
      <img
        src="https://img.icons8.com/ios-filled/50/284983/error--v1.png"
        alt="error--v1"
      />
      <h3>Unable to connect</h3>
      <p>
        The debugger is unable to reach the manifest, which should be running at
        <a href="https://localhost:8888/manifest.json"
          >localhost:8888/manifest.json</a
        >.
      </p>
      <p>
        Try running <span class="code">ulabs serve</span> in the project folder.
      </p>
      <button class="secondary" @click="load()">Try Again</button>
    </div>
    <div v-else-if="manifest" class="manifest">
      <div class="inputs">
        <div>
          <h3>Manifest Editor</h3>
          <div>
            <button @click="load">Reset</button>
            <button @click="save">Save</button>
          </div>
        </div>
        <div v-for="(item, key) in manifest" :key="key">
          <label :for="key">
            <div>
              <span>{{ key }} </span>
              <!-- <span> *</span> -->
            </div>
            <template v-if="isItemArrayType(item)">
              <div
                v-for="(arrayItem, arrayKey) in manifest[key]"
                :key="arrayKey"
                class="array-item"
              >
                <input v-model="manifest[key][arrayKey]" type="text" />
                <div>
                  <button @click="manifest[key].splice(arrayKey, 1)">X</button>
                </div>
              </div>
              <div>
                <button @click="manifest[key].push('')">+</button>
              </div>
            </template>
            <template v-if="isItemObjectType(item)">
              <input
                v-for="(arrayItem, arrayKey) in manifest[key]"
                :key="arrayKey"
                v-model="manifest[key][arrayKey]"
                type="text"
                :placeholder="arrayKey"
              />
            </template>
            <template v-if="isItemStringType(item)">
              <select v-if="key === 'type'" v-model="manifest[key]">
                <option
                  v-for="option of typeOptions"
                  :key="option"
                  :value="option"
                >
                  {{ option }}
                </option>
              </select>
              <template v-else>
                <input
                  v-if="item.length < 50"
                  :id="key"
                  v-model="manifest[key]"
                  type="text"
                  :placeholder="key"
                />
                <textarea v-else :id="key" v-model="manifest[key]"> </textarea>
              </template>
            </template>
            <template v-if="isItemBooleanType(item)">
              <select v-model="manifest[key]">
                <option
                  v-for="option of booleanOptions"
                  :key="option"
                  :value="option"
                >
                  {{ option }}
                </option>
              </select>
            </template>
            <template v-if="isItemArrayOfObjectsType(item)">
              <div
                v-for="(arrayItem, arrayKey) in manifest[key]"
                :key="arrayKey"
                class="nested-obj"
              >
                <div
                  v-for="(arrayItemNested, arrayKeyNested) in arrayItem"
                  :key="arrayKeyNested"
                >
                  <label :for="arrayKeyNested">
                    <div>
                      <span>{{ arrayKeyNested }} </span>
                      <!-- <span v-if="arrayItem.required"> *</span> -->
                    </div>
                    <!-- {{ arrayItem.type }} -->
                    <select
                      v-if="arrayKeyNested === 'type'"
                      v-model="arrayItem[arrayKeyNested]"
                    >
                      <option
                        v-for="option of questionTypeOptions"
                        :key="option"
                        :value="option"
                      >
                        {{ option }}
                      </option>
                    </select>
                    <select
                      v-else-if="arrayKeyNested === 'required'"
                      v-model="arrayItem[arrayKeyNested]"
                    >
                      <option
                        v-for="option of booleanOptions"
                        :key="option"
                        :value="option"
                      >
                        {{ option }}
                      </option>
                    </select>
                    <div
                      v-else-if="arrayKeyNested === 'options'"
                      class="nested-obj-1"
                    >
                      <div
                        v-for="(option, optionKey) in arrayItem.options"
                        :key="optionKey"
                      >
                        <input
                          v-model="arrayItem.options[optionKey]['name']"
                          type="text"
                          placeholder="name"
                        />
                        <input
                          v-model="arrayItem.options[optionKey]['value']"
                          type="text"
                          placeholder="value"
                        />
                        <div>
                          <button
                            @click="arrayItem.options.splice(optionKey, 1)"
                          >
                            X
                          </button>
                        </div>
                      </div>
                      <div>
                        <button
                          @click="
                            arrayItem.options.push({ name: '', value: '' })
                          "
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <input
                      v-else
                      :id="arrayKeyNested"
                      v-model="arrayItem[arrayKeyNested]"
                      type="text"
                      :placeholder="arrayKeyNested"
                    />
                  </label>
                </div>
                <div>
                  <button @click="manifest[key].splice(arrayKey, 1)">X</button>
                </div>
              </div>
              <div>
                <button @click="manifest[key].push(questionOptions)">+</button>
              </div>
            </template>
          </label>
        </div>
      </div>
      <div class="outputs">
        <vue-json-pretty :data="manifest" />
        <!-- <vue-json-pretty v-model="manifest" :editable="true" /> -->
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      manifestLoading: false,
      manifestFailed: false,
      manifest: null,
      typeOptions: ['url', 'create', 'file'],
      booleanOptions: [true, false],
      questionTypeOptions: ['textinput', 'textarea', 'select'],
      questionOptions: {
        type: 'textarea',
        name: '',
        label: '',
        example: '',
        required: false,
      },
    };
  },
  watch: {
    'manifest.questions': {
      handler(val) {
        if (val) {
          val.forEach((v) => {
            if (v.type === 'select' && !v.options) {
              this.$set(v, 'options', []);
            }
            if (v.type !== 'select' && v.options) {
              this.$delete(v, 'options');
            }
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    getItemType(item) {
      if (typeof item === 'object' && Array.isArray(item)) {
        const hasObj = item.some((value) => typeof value === 'object');
        if (hasObj) {
          return 'arrayOfObjects';
        }
        return 'array';
      }
      if (typeof item === 'object' && !Array.isArray(item)) {
        return 'object';
      }
      return typeof item;
    },
    isItemBooleanType(item) {
      return this.getItemType(item) === 'boolean';
    },
    isItemObjectType(item) {
      return this.getItemType(item) === 'object';
    },
    isItemArrayType(item) {
      return this.getItemType(item) === 'array';
    },
    isItemStringType(item) {
      return this.getItemType(item) === 'string';
    },
    isItemArrayOfObjectsType(item) {
      return this.getItemType(item) === 'arrayOfObjects';
    },
    async load() {
      this.manifestLoading = true;
      this.manifest = null;
      this.manifestFailed = false;
      this.values = {};
      try {
        const { data } = await axios.get('http://localhost:8888/manifest.json');
        data.questions.forEach((param) => {
          this.values[param.name] = param.example;
        });
        this.manifest = data;
        this.manifestFailed = false;
      } catch (err) {
        this.manifestFailed = true;
      }
      this.manifestLoading = false;
    },

    async save() {
      try {
        await axios.put('http://localhost:8888/manifest.json', this.manifest);
        alert('Manifest updated');
        this.load();
      } catch (err) {
        console.error(err);
      }
    },
    // addOption(arrayItem) {
    //   arrayItem.options.push({ name: '', value: '' });
    // },
    // removeOption(arrayItem, optionKey) {
    //   arrayItem.options.splice(optionKey, 1);
    // },
  },
  computed: {},
  mounted() {
    this.load();
  },
};
</script>
<style scoped>
.editor-view {
  display: flex;
  flex: 1;
}
.editor-view h3 {
  margin-top: 0px;
}
.manifest {
  display: flex;
  flex: 1;
  flex-direction: columns;
}
.metadata {
  background-color: #ccc;
  width: 300px;
  padding: 20px;
  overflow-y: auto;
  height: calc(100vh - 88px);
}
.inputs {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  height: calc(100vh - 88px);
}

.inputs > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inputs > div:first-child div {
  display: flex;
  gap: 5px;
}
.inputs label {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
}
.inputs input,
.inputs select,
.inputs textarea {
  font-size: inherit;
  font-family: inherit;
}
.outputs {
  flex: 1;
  padding: 20px;
  border-left: solid 1px #ccc;
  overflow-y: auto;
  height: calc(100vh - 88px);
}
.data-output {
  background-color: black;
  padding: 10px;
  color: white;
}
.markdown-output {
  background-color: #fcfcf3;
  padding: 10px;
}
.manifest-failed {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  padding: 20px;
  border: dashed 1px red;
  width: 400px;
}
.code {
  background-color: black;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
}
.nested-obj {
  background: #ebebeb;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-left: 10px;
}
.nested-obj-1 {
  background: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.nested-obj-1 div {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 5px;
  margin: 5px;
}
.array-item {
  display: flex;
  align-items: center;
  gap: 5px;
}
</style>
