<template>
  <div class="nav-bar">
    <div class="brand-name">Underling Labs</div>
    <div class="spacer"></div>
    <router-link
      class="nav-link"
      v-for="link of links"
      :key="link.to"
      :to="link.to"
    >
      {{ link.name }}
    </router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {
      links: [
        {
          to: 'editor',
          name: 'Editor',
        },
        {
          to: 'debug',
          name: 'Debugger',
        },
        {
          to: 'secrets',
          name: 'Secrets',
        },
        {
          to: 'openapi',
          name: 'OpenAPI',
        },
      ],
    };
  },
};
</script>
<style scoped>
.nav-bar {
  background-color: var(--primary-color);
  color: #ccc;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.brand-name {
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.nav-link {

}
</style>
