<template>
  <div class="debug-view">
    <div v-if="manifestLoading">
      <progress />
    </div>
    <div v-else-if="manifestFailed" class="manifest-failed">
      <img
        src="https://img.icons8.com/ios-filled/50/284983/error--v1.png"
        alt="error--v1"
      />
      <h3>Unable to connect</h3>
      <p>The debugger is unable to reach the manifest, which should be running at <a href="https://localhost:8888/manifest.json">localhost:8888/manifest.json</a>.</p>
      <p>Try running <span class="code">ulabs serve</span> in the project folder.</p>
      <button class="secondary" @click="load()">Try Again</button>
    </div>
    <div v-else-if="manifest" class="manifest">
      <div class="metadata">
        <h3>Metadata</h3>
        <button class="secondary" @click="load()">Refresh Metadata</button>
        <p>{{ manifest.command }}</p>
        <p>{{ manifest.description }}</p>
      </div>
      <div class="inputs">
        <h3>Questions</h3>
        <div
          v-for="param of manifest.questions"
          :key="param.name"
        >
          <label :for="param.name">
            <div>
              <span>{{ param.label }}</span>
              <span v-if="param.required"> *</span>
            </div>
            <textarea :id="param.name" v-model="values[param.name]" v-if="param.type == 'textarea'">
            </textarea>
            <select :id="param.name" v-model="values[param.name]"
              v-else-if="param.type == 'select'">
              <option v-for="option of param.options" :key="option.value"
                :value="option.value">{{ option.name }}</option>
            </select>
            <input :id="param.name" v-model="values[param.name]" type="text" v-else />
          </label>
        </div>
        <div>
          <button
            class="primary"
            @click="test()"
            :disabled="disableTestButton"
          >Test</button>
        </div>
      </div>
      <div class="outputs">
        <h3>Output</h3>
        <div v-if="!outputLoading && !output">Run to see output</div>
        <div v-if="outputLoading">
          <progress />
        </div>
        <div v-if="output">
          <h4>Data</h4>
          <div class="data-output">
            <code>{{ output.data }}</code>
          </div>
          <h4>Markdown</h4>
          <div class="markdown-output">
            <vue-markdown>{{ output.markdown }}</vue-markdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import VueMarkdown from 'vue-markdown';

export default {
  components: {
    VueMarkdown,
  },
  data() {
    return {
      manifestLoading: false,
      manifestFailed: false,
      manifest: null,
      values: {},
      outputLoading: false,
      outputFailed: false,
      output: null,
    };
  },
  methods: {
    async load() {
      this.manifestLoading = true;
      this.manifest = null;
      this.manifestFailed = false;
      this.values = {};
      try {
        const { data } = await axios.get('http://localhost:8888/manifest.json');
        data.questions.forEach((param) => {
          this.values[param.name] = param.example;
        });
        this.manifest = data;
        this.manifestFailed = false;
      } catch (err) {
        this.manifestFailed = true;
      }
      this.manifestLoading = false;
    },
    async test() {
      this.outputLoading = true;
      this.output = null;
      this.outputFailed = false;
      try {
        const { data } = await axios.get('http://localhost:8888/invoke', {
          params: this.values,
        });
        console.log(data);
        this.output = data;
        this.outputFailed = false;
      } catch (err) {
        this.outputFailed = true;
      }
      this.outputLoading = false;
    },
  },
  computed: {
    disableTestButton() {
      if (!this.manifest) {
        return true;
      }
      return this.manifest.questions.filter((q) => {
        const disp = (q.required && !this.values[q.name]);
        return disp;
      }).length > 0;
    },
  },
  mounted() {
    this.load();
  },
};
</script>
<style scoped>
.debug-view {
  display: flex;
  flex: 1;
}
.debug-view h3 {
  margin-top: 0px;
}
.manifest {
  display: flex;
  flex: 1;
  width: 100vw;
}
.metadata {
  background-color: #ccc;
  width: 300px;
  padding: 20px;
  overflow-y: auto;
  height: calc(100vh - 88px);
}
.inputs {
  flex: 1;
  padding: 20px;
  overflow-y:auto;
  height: calc(100vh - 88px);
}
.inputs label {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
}
.inputs input, .inputs select, .inputs textarea {
  font-size: inherit;
  font-family: inherit;
}
.outputs {
  flex: 1;
  padding: 20px;
  border-left: solid 1px #ccc;
  overflow-y:auto;
  height: calc(100vh - 88px);
}
.data-output {
  background-color: black;
  padding: 10px;
  color: white;
}
.markdown-output {
  background-color: #fcfcf3;
  padding: 10px;
}
.manifest-failed {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  padding: 20px;
  border: dashed 1px red;
  width: 400px;
}
.code {
  background-color: black;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
