<template>
  <div class="home">
    <app-nav-bar />
    <div class="main">
      <router-view />
    </div>
  </div>
</template>

<script>
import AppNavBar from '@/components/NavBar.vue';

export default {
  components: {
    AppNavBar,
  },
};
</script>
<style scoped>
.home {
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  flex-direction: column;
}
.main {
  display: flex;
  flex: 1;
  overflow-y: auto;
}
</style>
