import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import DebugView from '../views/DebugView.vue';
import EditorView from '../views/EditorView.vue';
import OpenapiView from '../views/OpenapiView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: HomeView,
    children: [
      {
        path: '',
        name: 'debug',
        component: DebugView,
      },
      {
        path: '/editor',
        name: 'editor',
        component: EditorView,
      },
      {
        path: '/openapi',
        name: 'openapi',
        component: OpenapiView,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
